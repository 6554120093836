import ClientSideDID from "@/components/utility/client-side-did";
import {
  INLINES_ASSET_HYPERLINK,
  INLINES_ENTRY_HYPERLINK,
  INLINES_HYPERLINK,
} from "@/components/utility/rich-text-options/rich-text-renderers";
import { DesignSystemComponent, DidT } from "@/types/design-system-types";
import { cn } from "@/utils/helpers";
import { DidDebugger } from "@/utils/system/component-debugger";
import { ComponentError } from "@/utils/system/component-error";
import Page from "@/utils/system/page";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { ReactNode } from "react";

const Did = ({
  data, // All incoming Contentful data
  id, // For scroll spy anchors
  debug, // Debugging info from Contentful

  // Contentful controlled Props
  value = data?.fields.value,
  clientSide = data?.fields.clientSide ?? false,
  className,
  ...props
}: DesignSystemComponent<DidT>) => {
  try {
    if (!value) {
      return <></>;
    }

    if (clientSide) {
      return <ClientSideDID data={data} debug={debug} {...props} />;
    }

    return (
      <span
        onClick={() => Page.editField(data, "value")}
        {...props}
        data-server-side={true}
        suppressHydrationWarning={true}
        className={cn(
          "n-did relative inline w-auto group max-h-min",
          `level-${data?.fields.depth}`
        )}
        ref={id}
      >
        {/* DEBUGGING INFO FOR LOCAL */}
        {debug && <DidDebugger data={data} />}

        {documentToReactComponents(value, {
          renderNode: {
            // Render without wrapping paragraph tag to avoid hydration errors
            [BLOCKS.PARAGRAPH]: (node: any, children: ReactNode) => children,
            [INLINES.HYPERLINK]: (node: any, children: ReactNode) =>
              INLINES_HYPERLINK(node, children),
            [INLINES.ENTRY_HYPERLINK]: (node: any, children: ReactNode) =>
              INLINES_ENTRY_HYPERLINK(node, children),
            [INLINES.ASSET_HYPERLINK]: (node: any, children: ReactNode) =>
              INLINES_ASSET_HYPERLINK(node, children),
          },
        })}
      </span>
    );
  } catch (error) {
    return <ComponentError error={error} data={data} />;
  }
};

export default Did;

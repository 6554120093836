import HeadlessFetcher from "@/services/fetchers/headless-fetcher";
import { getContentfulEnvironment, getRouteDetails } from "@/utils/helpers";
import { DidDebugger } from "@/utils/system/component-debugger";
import { Spinner } from "@contentful/f36-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { useRouter } from "next/router";
import { ReactNode, useEffect, useState } from "react";

const ClientSideDID = ({ data, debug, ...props }: { data: any; debug: any }) => {
  const id = data?.sys?.id;

  const router = useRouter();
  const { isPreview } = getContentfulEnvironment(router);
  const { locale } = getRouteDetails(router);

  const contentful = isPreview ? "preview" : "published";

  const [DID, setDID] = useState(null);

  useEffect(() => {
    const getDIDEntry = async () => {
      try {
        const plainTextDidPromise = await fetch(
          `/api/neptune/contentful/dids?env=preview&didId=${id}&locale=${locale}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const didJson = await plainTextDidPromise.json();
        const did = didJson.did;

        did && setDID(did?.fields?.value as any);
      } catch (e) {
        console.log(e);
      }
    };

    getDIDEntry();
  }, []);

  // if(DID) return DID.fields.value;
  return (
    <span className="min-w-[4ch]">
      {DID
        ? documentToReactComponents(DID, {
            renderNode: {
              [BLOCKS.PARAGRAPH]: (_: any, children: ReactNode) => {
                return (
                  <span
                    className="relative h-min max-h-min"
                    data-client-side={true}
                    {...props}
                  >
                    {debug && <DidDebugger data={data} />}
                    {children}
                  </span>
                );
              },
            },
          })
        : "XX"}
    </span>
  );
};

export default ClientSideDID;
